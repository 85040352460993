import React from "react";
import styled from "styled-components";
// import { motion } from "framer-motion";
// import Typed from "react-typed";

// this is a function that returns all the elements in a footer
// this component is called Footer with a capital F
function Footer() {
  return (
    <FooterContainer>
      <div style={{ maxWidth: "300px", padding: "20px" }}>
        <h3>credits</h3>{" "}
        <p>
          Paired programmed by{" "}
          <a
            href="https://lulato.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>Lulato</b>
          </a>{" "}
          and Alessandra Feuerberg
        </p>
      </div>
      <p>&copy;copyright 2021</p>
    </FooterContainer>
  );
}

// const text = {
//   width: "88%",
//   overflow: "hidden",
//   fontFamily: `"GoshaSans-Regular", sans-serif`,
//   color: "#000000",
//   fontSize: 24,
//   letterSpacing: 0,
//   lineHeight: 1.2,
//   textAlign: "center",
// };

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
z-index: 5;
  padding: 0 20px 0 20px;
  font-size: 2px;

  h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }
  p {
    font-size: 16px;
    line-height: 1.4;
  }
`;

export default Footer;
