import React from "react";
import { motion } from "framer-motion";
import Typed from "react-typed";
import styled from "styled-components";
import { device } from "../Utils";

function PersonalHighlights() {
  return (
    <TypographyStyles>
      <Typed
        typeSpeed={65}
        fontFamily="GoshaSans-Bold"
        fontSize={100}
        loop={true}
        strings={[
          "curious and inquisitive, with a keen passion for honey on goat cheese",
          "curious and inquisitive, with a keen passion for philosophizing",
          "curious and inquisitive, with a keen passion for the sound of my bike when I stop pedaling",
          "curious and inquisitive, with a keen passion for smelling new books",
          "curious and inquisitive, with a keen passion for the excitement of a live soccer match",
          "curious and inquisitive, with a keen passion for odd sans serifs",
          "curious and inquisitive, with a keen passion for hosting guests",
          "curious and inquisitive, with a keen passion for funky jazz",
          "curious and inquisitive, with a keen passion for nature",
          "curious and inquisitive, with a keen passion for japanese bookstores",
          "curious and inquisitive, with a keen passion for unplanned adventures ",
        ]}
        style={{
          fontFamily: `"GoshaSans-Regular", "Gosha Sans", sans-serif`,
          color: "#000000",
          // fontSize: 24,
          letterSpacing: 0,
          lineHeight: 1.2,
          fontWeight: 400,
        }}
      />
    </TypographyStyles>
  );
}

const TypographyStyles = styled(motion.div)`
  font-size: 18px;
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export default PersonalHighlights;
