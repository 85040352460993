import gql from "graphql-tag";

const PROJECTS_QUERY = gql`
  query Projects {
    projects {
      id
      Title
      ProjectType
      coverImage {
        url
        hash
        formats
      }
      GridArea
      outcomeImage1 {
        url
      }
      outcomeImage2 {
        url
      }
    }
  }
`;

export default PROJECTS_QUERY;
