import React from "react";
// import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";

import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { ApolloProvider } from "react-apollo";
import client from "./utils/apolloClient";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,

    rootElement
  );
} else {
  render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,

    rootElement
  );
}

// ReactDOM.render(
//   <React.StrictMode>
//     <ApolloProvider client={client}>
//       <App />
//     </ApolloProvider>
//   </React.StrictMode>,
//   rootElement
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
