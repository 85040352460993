import * as React from "react";

function Burger({ onClick, style }) {
  return (
    <svg
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
    >
      <path d="M 8 12 L 38 12 L 38 15 L 8 15 Z" fill="hsl(0, 0%, 0%)"></path>
      <path d="M 16 20 L 38 20 L 38 23 L 16 23 Z" fill="hsl(0, 0%, 0%)"></path>
      <path d="M 8 28 L 38 28 L 38 31 L 8 31 Z" fill="hsl(0, 0%, 0%)"></path>
    </svg>
  );
}

export default Burger;
