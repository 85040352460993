import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Query from "../components/Query";
import PRODUCT_QUERY from "../queries/product";

export default function ProductPage({ match, location }) {
  const id = match.params.id;

  return (
    <Query query={PRODUCT_QUERY} id={id}>
      {({ data: { page } }) => {
        console.log(page.Title);
        return (
          <Container>
            <h1>{page.Title}</h1>
            <h1>{page.coverImage.url}</h1>
          </Container>
        );
      }}
    </Query>
  );
}

const Container = styled(motion.div)`
  background: green;
  padding-top: 55px;
`;
