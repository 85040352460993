import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

import { device } from "./Utils";
import { ArrowIcon } from "./Icons/Arrows";

function ProjectCard(props) {
  const { title, imageURL, variants, gridArea, to } = props;

  // if (!data) return null;
  // console.log(data);
  // declare the return
  return (
    <StyledMotionDiv variants={variants} gridArea={gridArea}>
      <Container to={to}>
        <Image img={imageURL} alt={`${title}-project-image`} />
        <ProjectInfo>
          <Title>{title}</Title>
          <ArrowIcon />
        </ProjectInfo>
      </Container>
    </StyledMotionDiv>
  );
}

const StyledMotionDiv = styled(motion.div)`
  /* background: green; */
  grid-area: ${(props) => props.gridArea || ""};
`;

// Container for Project Card
const Container = styled(Link)`
  display: block;
  width: 100%;
  height: 100%;
  background-color: " ";

  display: grid;
  grid-template-rows: 1fr 50px;
  text-decoration: none;
`;

const Image = styled.div`
  /* width: 100%; */
  background-color: lightcoral;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: auto;
`;

const ProjectInfo = styled.div`
  width: 100%;
  /* height: 36px; */
  padding: 12px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: " ";
  border: 0.5px solid #000000;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h5`
  font-family: "GoshaSans-Ultralight", "Gosha Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 0.8;
  text-align: left;
  color: #000;
  margin: 0;
  @media ${device.desktop} {
    font-size: 1.8rem;
  }
`;

export default ProjectCard;
