import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Nav from "./Nav";
// import Footer from "./Footer";
import Projects from "./pages/Projects";
// import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import Page404 from "./pages/Page404";
import ProjectDetailPage from "./pages/ProjectDetailPage";
import ProductPage from "./pages/ProductPage";

function App() {
  return (
    <Router>
      <Nav style={{ background: "green" }} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/project/:id" component={ProjectDetailPage} />
        <Route exact path="/products/:id" component={ProductPage} />
        <Route component={Page404} />
      </Switch>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
