import * as React from "react";

function LogoAF({ style, onClick }) {
  return (
    <svg
      style={style}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="40"
    >
      <g transform="translate(0 5)">
        <path
          d="M 31.167 23.374 L 31.167 30.866 L 19.318 30.866 L 19.318 27.9 C 17.799 30.06 15.431 31.496 10.874 31.496 C 3.468 31.465 0 26.195 0 20.667 C 0 15.138 3.468 9.889 10.874 9.889 C 15.431 9.889 17.799 11.263 19.318 13.485 L 19.318 6.892 L 3.52 6.892 L 3.52 0 L 27.238 0 L 27.238 23.374 Z M 19.318 20.667 C 19.318 18.269 17.747 16.347 13.609 16.347 C 9.47 16.347 7.899 18.28 7.899 20.667 C 7.899 23.054 9.481 24.996 13.619 24.996 C 17.757 24.996 19.318 23.074 19.318 20.667 Z M 44.534 23.674 L 44.534 30.907 L 36.667 30.907 L 36.667 23.674 Z"
          fill="rgb(0,0,0)"
        ></path>
      </g>
    </svg>
  );
}

export default LogoAF;
