import * as React from "react";
import ReactDom from "react-dom";
import { motion, AnimatePresence } from "framer-motion";

function Modal({ children, isVisible, setVisible }) {
  if (!isVisible) return null;
  return ReactDom.createPortal(
    <>
      <motion.div
        style={{
          //   background: "rgba(0,0,0,0.6)",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 2000,
        }}
      >
        <AnimatePresence>
          {isVisible && (
            <motion.div
              style={{
                // background: "white",
                padding: 12,
                borderRadius: 24,
                // width: "66vw",
                // height: "50vh",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              initial={{ opacity: 0, scale: 0.75 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0 }}
            >
              {/* <div
                onClick={setVisible}
                style={{
                  position: "absolute",
                  right: 12,
                  top: 12,
                  width: 24,
                  height: 24,
                  background: "#c1c1c1",
                }}
              /> */}

              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>,
    document.getElementById("portal")
  );
}

export default Modal;
