import React from "react";
import { motion } from "framer-motion";

// Animated Arrow

function AnimatedArrow() {
  return (
    <div
      style={{
        width: 105,
        height: 105,
        position: "absolute",
        right: 30,
        top: 86,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        gridTemplateAreas: `"area1"`,
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <motion.div
        animate={{ rotate: 360 * 2 }}
        transition={{ duration: 20, loop: Infinity }}
        style={animatedTextFrame}
      />
      <div style={round} />
      <div style={ArrowFrame} />
    </div>
  );
}

const round = {
  boxSizing: "border-box",
  width: 54,
  height: 54,
  // overflow: "hidden",
  backgroundColor: "#DCEFEF",
  borderRadius: 27,
  border: "0.5px solid #222",
  gridArea: "area1",
};

const animatedTextFrame = {
  width: 105,
  height: 105,
  overflow: "visible",
  backgroundImage:
    "url(https://res.cloudinary.com/dcaaew01j/image/upload/v1591817580/Alessandra_Portfolio_Content/AboutMe/AnimatedText2-12_w2co4x.png)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  imageRendering: "auto",
  gridArea: "area1",
};

const ArrowFrame = {
  width: 105,
  height: 105,
  overflow: "visible",
  backgroundImage:
    "url(https://res.cloudinary.com/dcaaew01j/image/upload/v1591799148/Alessandra_Portfolio_Content/AboutMe/ArrowAbout_jspijf.png)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  imageRendering: "auto",
  gridArea: "area1",
};

export default AnimatedArrow;
