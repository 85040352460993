import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

import { ArrowIcon } from "../Icons/Arrows";
import { device } from "../Utils";
import PersonalHighlights from "../components/PersonalHighlights";
import AnimatedEye from "../components/AnimatedEye";
import Footer from "../Footer";
import Modal from "../components/Modal";

// import "../styles.css";

export default function HomePage() {
  const [isShown, setIsShown] = React.useState(false);
  // const [isModalVisible, setModalVisible] = React.useState(false);

  return (
    <>
      <Container>
        <HomePageContentContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: "3rem 0 3rem 3rem",
            }}
          >
            <AnimatedEye />
          </div>

          <IntroText>
            <h1>I'm Alessandra,</h1>
            <p>
              <motion.span
                onMouseOver={() => setIsShown(true)}
                // onMouseLeave={() => setIsShown(false)}
              >
                American-born
              </motion.span>{" "}
              <a
                href="https://www.google.com/search?q=venezuela&oq=Venezuela&aqs=chrome.0.0i355i433j46i433j0i131i433j0i433j0i457j0i395i402l2j0i395i433l3.1423j1j15&sourceid=chrome&ie=UTF-8/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Venezuelan
              </a>{" "}
              designer. I specialize in branding, identity systems and interface
              design.
            </p>
            <PersonalHighlights></PersonalHighlights>
          </IntroText>
          <CTAsection>
            <ActionLink to="/projects">
              <span>see projects</span>
              <ArrowIcon style={{ marginLeft: 10 }} />
            </ActionLink>
          </CTAsection>
        </HomePageContentContainer>
      </Container>
      <Footer />
      <Modal isVisible={isShown} setVisible={() => setIsShown(false)}>
        <img
          src="https://res.cloudinary.com/dcaaew01j/image/upload/v1610425813/ThisIsAlessandraFeuerberg_2x_v1dmqb.jpg"
          alt="new"
          onMouseLeave={() => setIsShown(false)}
        />
      </Modal>
    </>
  );
}

const CTAsection = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0px 0px 0px;
  @media ${device.tablet} {
    padding: 100px 10px 0 0;
  }
`;

const Container = styled.div`
  /* padding: 1.2rem; */
  padding-top: 28px;
  color: #000;
  background: #f0ede8;
  h1 {
    font-size: 58px;
  }
  p {
    font-size: 28px;
    letter-spacing: 0.1;
    line-height: 1.5;
    margin-bottom: 40px;
  }
  a {
    color: #000000;
    :hover {
      color: #ff3c00;
    }
    display: inline;
  }
  span {
    text-decoration-line: underline;
    /* text-decoration-thickness: 0.1px; */
  }
  @media ${device.tablet} {
    h1 {
      font-size: 70px;
    }
    p {
      font-size: 32px;
      width: 100%;
    }
  }
  @media ${device.laptop} {
    h1 {
      font-size: 88px;
    }
    p {
      font-size: 40px;
      width: 90%;
    }
  }
`;

const HomePageContentContainer = styled.div`
  padding: 2rem;
  @media ${device.laptop} {
    padding: 4rem;
  }
`;

const IntroText = styled.div`
  h1 {
    font-family: "GoshaSans-Bold", "Gosha Sans", sans-serif;
    color: #000000;
    font-size: 42px;
    line-height: 1;
    font-weight: 700;
    text-align: left;
    margin-bottom: 20px;
  }
  p {
    font-family: "StagSans-Thin", "Stag Sans", sans-serif;
    color: #000000;
    font-size: 38px;
    letter-spacing: 0.1px;
    line-height: 1.3;
    font-weight: 200;
  }
  @media ${device.tablet} {
    h1 {
      font-family: "GoshaSans-Bold", "Gosha Sans", sans-serif;
      color: #000000;
      font-size: 92px;
      line-height: 1;
      font-weight: 700;
      text-align: left;
    }
    p {
      font-family: "StagSans-Thin", "Stag Sans", sans-serif;
      color: #000000;
      font-size: 72px;
      letter-spacing: 0.1px;
      line-height: 1.3;
      font-weight: 200;
    }
  }
  @media ${device.laptop} {
    h1 {
      font-family: "GoshaSans-Bold", "Gosha Sans", sans-serif;
      color: #000000;
      font-size: 92px;
      line-height: 1;
      font-weight: 700;
      text-align: left;
    }
    p {
      font-family: "StagSans-Thin", "Stag Sans", sans-serif;
      color: #000000;
      font-size: 72px;
      letter-spacing: 0.1px;
      line-height: 1.3;
      font-weight: 200;
    }
  }
`;

const ActionLink = styled(Link)`
  font-family: "GoshaSans-Bold", "Gosha Sans", sans-serif;
  color: #000000;
  font-size: 20px;
  :hover {
    color: #ff3c00;
  }
  @media ${device.tablet} {
    font-size: 24px;
  }
`;

// const subline = {
//   width: "100%",
//   height: "100%",
//   overflow: "hidden",
//   fontFamily: `"StagSans-Light", sans-serif`,
//   fontSize: 28,
//   letterSpacing: 0.1,
//   lineHeight: 1.5,
//   marginBottom: 40,
// };
