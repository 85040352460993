import React from "react";
import styled from "styled-components";
// import Footer from "../Footer";

import { device } from "../Utils";

// import { BehanceIcon, InstagramIcon, LinkedInIcon } from "../Icons/Arrows";

export default function ContactPage() {
  return (
    <Container>
      <ContactContent>
        <ImageContainer />
        <ContactLinks>
          <ContactText>
            <h5>contact alessandra.</h5>
            <p>
              Hey! Get in touch with me via email{" "}
              <a href="mailto:alessandra.feuerberg@gmail.com">
                alessandra.feuerberg@gmail.com
              </a>
              , or{" "}
              <a
                href="https://www.instagram.com/a.dessigner/"
                target="_blank"
                rel="noopener noreferrer"
              >
                #instagram
              </a>
              {/* {", "} */}
              {/* <a
                href="https://www.behance.net/alessandrafeuerberg"
                target="_blank"
                rel="noopener noreferrer"
              >
                #behance
              </a> */}
              {/* {", "} */}
              {/* <a
                href="https://www.linkedin.com/in/alessandra-feuerberg/"
                target="_blank"
                rel="noopener noreferrer"
              >
                #linkedin
              </a> */}
              .
            </p>
            {/* <a style={mail} href="mailto:alessandra.feuerberg@gmail.com">
              alessandra.feuerberg@gmail.com
            </a> */}
          </ContactText>

          {/* <SocialIcons>
            <LinkedInIcon />
            <InstagramIcon />
            <BehanceIcon />
          </SocialIcons> */}
        </ContactLinks>
        {/* <Footer /> */}
      </ContactContent>
    </Container>
  );
}

const Container = styled.div`
  /* padding-top: 60px; */
  background: #f7d6d3;
  min-height: 700px;
  height: 100vh;
  /*  */
`;

const ContactLinks = styled.div`
  background: rgb(247, 214, 211);
  /* height: 45%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  padding-top: 20px;
`;

const ContactContent = styled.section`
  /* background: blue; */
  height: 95vh;
  /*  */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40% 50% auto;
  @media ${device.tablet} {
    grid-template-rows: 50% 50% auto;
  }
  @media ${device.laptop} {
    grid-template-rows: 120% 50% auto;
  }
`;

const ImageContainer = styled.div`
  grid-row: 1 / 2;

  width: 100%;
  /* height: 330px; */
  overflow: visible;
  background-image: url(https://res.cloudinary.com/dcaaew01j/image/upload/v1608741587/Alessandra_Portfolio_Content/ContactImage/ContactAlessandraFeuerberg_2x_r1cgqp.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: auto;
`;

// const SocialIcons = styled.div`
//   /* background: green; */
//   width: 200px;
//   padding: 1rem;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   /* margin: auto; */
//   /* position: absolute; */
// `;

const ContactText = styled.div`
  /* background: green; */
  width: 72%;
  h5 {
    font-family: "GoshaSans-Bold", sans-serif;
    color: #000000;
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    margin-top: 16px;
  }
  p {
    font-size: 22px;
    text-align: center;
    margin-top: 8px;
    line-height: 1.4;
  }
  a {
    font-style: bold;
    color: #000000;
    display: inline;
  }
  span {
    text-decoration: underline;
    text-decoration-thickness: 0.1px;
  }
  @media ${device.tablet} {
    /* background: yellow; */
    width: 58%;
  }
  @media ${device.laptop} {
    /* background: blue; */
    width: 28%;
  }
`;
