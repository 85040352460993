import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion, useCycle } from "framer-motion";

import LogoAF from "./Logo";
import Burger from "./components/Burger";
import { NavArrow } from "./Icons/Arrows";
import { device } from "./Utils";

function Nav() {
  const [isExpanded, setIsExpanded] = useCycle(false, true);

  const variants = {
    expanded: {
      height: "100vh",
      background: "#F0EDE8",
      zIndex: 30,
      transition: {
        type: "spring",
        delay: 0,
        stiffness: 500,
        damping: 60,
        mass: 1,
        staggerChildren: 0.5,
      },
    },
    contracted: {
      height: 60,
      // opacity: 0,
      background: "#F0EDE8",
      zIndex: 30,
      transition: {
        type: "spring",
        delay: 0.7,
        stiffness: 500,
        damping: 60,
        mass: 1,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <motion.div
      animate={isExpanded ? "expanded" : "contracted"}
      initial="contracted"
      variants={variants}
      style={{
        height: 60,
        overflow: "hidden",
        // paddingTop: 45,
        position: "absolute",
        width: "100%",
        background: "",
        zIndex: "30",
      }}
    >
      <Sticky setIsExpanded={setIsExpanded} />
      <NavList isExpanded={isExpanded} onTap={setIsExpanded} />
    </motion.div>
  );
}

// Logo and Burger remain sticy at the top
function Sticky({ setIsExpanded }) {
  return (
    <StickyContainer>
      <Link to="/">
        <LogoAF />
      </Link>
      <Burger
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsExpanded();
        }}
      />
    </StickyContainer>
  );
}

// CSS Style elements for the sticky container
const StickyContainer = styled.div`
  /* background-color: rgba(189, 189, 189, 0.12); */
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
`;

// List of Links
function NavList({ isExpanded, onTap }) {
  const variants = {
    contracted: {
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
    expanded: {
      transition: {
        staggerChildren: 0.2,
        staggerDirection: 1,
      },
    },
  };
  return (
    <motion.ul
      animate={isExpanded ? "expanded" : "contracted"}
      initial="contracted"
      transition={{ staggerChildren: 0.1 }}
      variants={variants}
      onTap={onTap}
      style={{ padding: "3rem", paddingTop: 80 }}
    >
      <NavItem to="/" text="Home" />
      <NavItem to="/projects" text="Projects" />
      <NavItem to="/about" text="About" />
      <NavItem to="/contact" text="Contact" />
    </motion.ul>
  );
}

// Single NAV LINK
function NavItem({ to, text }) {
  const variants = {
    contracted: { opacity: 0, y: -10 },
    expanded: { opacity: 1, y: 0 },
  };
  return (
    <motion.div variants={variants}>
      <StyledLink to={to}>
        <li style={{ listStyle: "none" }}>{text}</li>
        <NavArrow />
      </StyledLink>
    </motion.div>
  );
}
// Router Link wrapper arround styles to make it look 💅 pretty
const StyledLink = styled(Link)`
  /* background: green; */
  text-decoration: none;
  padding: 2rem 0 1rem 0;

  width: 100%;
  flex: 1;
  margin: 0;

  border-style: solid;
  border-color: #000;
  border-bottom-width: 0.5px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;

  font-family: "GoshaSans-Bold", "Gosha Sans", sans-serif;
  color: #000000;
  font-size: 48px;
  letter-spacing: 0px;
  line-height: 1;
  font-weight: 700;
  text-align: left;
  /* pseudo element of hover */
  :hover {
    color: #ff3c00;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* If the viewport is laptop */
  @media ${device.laptop} {
    /* background: yellow; */
    font-size: 88px;
    padding: 2rem 0 1rem 0;
  }
`;

export default Nav;
