import React from "react";
import styled from "styled-components";
import Query from "../components/Query";
import { device } from "../Utils";
import { Link } from "react-router-dom";

import PROJECTS_QUERY from "../queries/projects/projects";
import { SuggestedArrow } from "../Icons/Arrows";
// import { from } from "apollo-boost";

function SuggestedProjects({ currentId }) {
  console.log(currentId);
  return (
    <>
      <SuggestedContainer>
        <SectionHeader>
          <h2>other projects</h2>
        </SectionHeader>

        <Query query={PROJECTS_QUERY}>
          {({ data: { projects } }) => {
            // Try to make the random thing here
            return <Projects projects={projects} currentId={currentId} />;
          }}
        </Query>
      </SuggestedContainer>
    </>
  );
}

//this is the main function
function Projects({ projects, currentId }) {
  const List = projects.map((project) => {
    if (project.id === currentId) {
      return null;
      // console.log("match");
    } else {
      return (
        <SingleProject
          key={project.id}
          // key={project.id}
          to={{ pathname: `/project/${project.id}` }}
          title={project.Title}
          imageURL={project.coverImage.url}
        />
      );
    }
  });
  return <HStack>{List}</HStack>;
}

const HStack = styled.div`
  display: flex;
  flex-direction: horizontal;
  padding: 2rem 0 1rem 0;
  width: 100%;
  height: auto;
  /* height: 100%; */
  overflow: auto;
  white-space: nowrap;

  @media ${device.laptop} {
    /* background: lightsteelblue; */

    /* width: 524px; */
    height: auto;
    /* overflow: hidden; */
  }
`;

function SingleProject({ key, title, imageURL, to }) {
  return (
    <SingleProjectContainer to={to}>
      <Image imageUrl={imageURL} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 8,
        }}
      >
        <p>{title}</p>
        <SuggestedArrow />
      </div>
    </SingleProjectContainer>
  );
}

const Image = styled.div`
  width: 157px;
  height: 128px;
  /* overflow: hidden; */
  background-image: url(${(props) => (props.imageUrl ? props.imageUrl : "")});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: auto;
  @media ${device.laptop} {
    width: 524px;
    height: 362px;
  }
`;

const SingleProjectContainer = styled(Link)`
  font-family: "StagSans-Light", sans-serif;
  text-decoration: none;
  color: #000;
  width: 157px;
  height: 157px;
  margin-right: 16px;

  /* Some rules for paragpah that are only inside singleProj */
  p {
    font-size: 16px;
  }
  /* Some rules for laptop */
  @media ${device.laptop} {
    width: 526px;
    height: 418px;
    /* background: lightgreen; */
    margin-right: 48px;
    font-size: 48px;
    p {
      font-size: 30px;
    }
  }
`;

const SuggestedContainer = styled.div`
  position: relative;
  width: 100%; /* Think of a way to programatically solve this */
  height: 255px;
  padding-left: 20px;
  @media ${device.laptop} {
    /* background: #fff; */
  }
`;

// IM WORKING HERE RIGHT NOW.
const SectionHeader = styled.div`
  /* background-color: papayawhip; */
  border-bottom: 0.5px solid #000;
  padding: 1rem 0 0.5rem 0;

  /* padding: 1rem; */
  width: 100%;
  /* background: yellow; */
`;

// Makes this component avaiable
export default SuggestedProjects;
