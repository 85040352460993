import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { device } from "../Utils";
import ProjectCard from "../ProjectCard";
// import Footer from "../Footer";

import Query from "../components/Query";
import PROJECTS_QUERY from "../queries/projects/projects";

export default function ProjectsPage() {
  return (
    <div style={{ paddingTop: 44 }}>
      <div style={{ padding: 32, paddingBottom: 0 }}>
        <h1>all projects</h1>
      </div>
      <Query query={PROJECTS_QUERY}>
        {({ data: { projects } }) => {
          return <ProjectList projects={projects} />;
        }}
      </Query>
      {/* <Footer /> */}
    </div>
  );
}

function ProjectList({ projects }) {
  // motion states values for the container
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  // motion states values for the each item
  const item = {
    hidden: { opacity: 0, y: -10 },
    show: { opacity: 1, y: 0 },
  };
  const List = projects.map((project) => {
    const title = project.Title;
    const domainStart = `https://res.cloudinary.com/dcaaew01j/image/upload/`;
    const sizeAlteration = "h_800/";
    const hash = project.coverImage.hash;
    const coverImage = domainStart + sizeAlteration + hash + ".jpg";
    const GridArea = project.GridArea;
    return (
      <ProjectCard
        key={project.id}
        to={{ pathname: `/project/${project.id}` }}
        imageURL={coverImage}
        title={title}
        gridArea={`${GridArea}`}
        variants={item}
      />
    );
  });

  return (
    <Grid
      initial="hidden"
      animate="show"
      variants={container}
      transition={{
        delayChildren: 0.1,
        staggerChildren: 0.1,
      }}
    >
      {List}
    </Grid>
  );
}

const Grid = styled(motion.div)`
  background: linear-gradient(to top, #fbc2eb 80%, #a6c1ee 100%);
  background: #f0ede8;
  height: 1600px;
  width: 100%;
  display: grid;
  padding: 16px;
  padding-bottom: 64px;
  grid-gap: 32px;
  @media ${device.phone} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(16, 1fr);
    grid-gap: 20px;
    padding: 20px;
    height: 1000px;
    grid-template-areas:
      "Area1 Area2"
      "Area1 Area2"
      "Area1 Area2"
      "Area1 Area4"
      "Area1 Area4"
      "Area1 Area4"
      "Area3 Area4"
      "Area3 Area4"
      "Area3 Area6"
      "Area5 Area6"
      "Area5 Area6"
      "Area5 Area6"
      "Area5 Area8"
      "Area7 Area8"
      "Area7 Area8"
      "Area7 Area8";
  }
  @media ${device.tablet} {
    height: 1806px;
    grid-gap: 32px;
    padding: 32px;
  }
  @media ${device.laptop} {
    /* height: 3000px; */
    height: auto;
    /* background: orange; */
    padding: 32px;
    padding-bottom: 64px;
    grid-gap: 32px;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(12, 60px);
    grid-template-areas:
      "Area1 Area2 Area3"
      "Area1 Area2 Area3"
      "Area1 Area2 Area3"
      "Area1 Area5 Area3"
      "Area1 Area5 Area6"
      "Area1 Area5 Area6"
      "Area1 Area5 Area6"
      "Area1 Area5 Area6"
      "Area4 Area5 Area8"
      "Area4 Area7 Area8"
      "Area4 Area7 Area8"
      "Area4 Area7 Area8";
  }
  @media ${device.desktop} {
    /* height: 2087px; */
  }
`;
