import gql from "graphql-tag";

const PRODUCT_QUERY = gql`
  query Page($id: ID!) {
    page(id: $id) {
      id
      coverImage {
        url
      }
      Title
      Content
    }
  }
`;

export default PRODUCT_QUERY;
