const size = {
  mobileS: "320px",
  tabletS: "642px",
  tablet: "768px",
  tabletB: "890px",
  laptop: "1024px",
  desktop: "1280px",
};

export const device = {
  mobileS: `(min-width:${size.mobileS})`,
  tabletS: `(min-width:${size.tabletS})`,
  tablet: `(min-width:${size.tablet})`,
  tabletB: `(min-width:${size.tabletB})`,
  laptop: `(min-width:${size.laptop})`,
  desktop: `(min-width:${size.desktop})`,
};
