import React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

import { device } from "../Utils";
import AnimatedArrow from "../components/AnimatedArrow";

const cardData = {
  intro: {
    title: "2012",
    info: "Moved to Paris. Learned French and studied art. The idea of graphic design as the visual language clicked.",
  },
  focuses: {
    title: "2018",
    info:
      "Graduated from MassArt with honors and a BFA in graphic design.",
  },
  background: {
    title: "Always",
    info:
      "Searching for meaning. Creating with intention.",
  },
  education: {
    title: "Currently",
    info:
      "Reading The Unbearable Lightness of Being. Shoe Dog too. Have suggestions?",
  },
  experience: {
    title: "2017",
    info:
      "Listened to 66,612 minutes of music.",
  },
  connecting: {
    title: "2019",
    info:
      "Created a book called Only Marvelous Things. 4364 entires. 41+ contributors. Still going around.",
  },

};

const AlessandraName = styled.div`
  width: 100%;
  height: 800px;
  overflow: hidden;
  background-image: url(https://res.cloudinary.com/dcaaew01j/image/upload/v1591796189/Alessandra_Portfolio_Content/AboutMe/iPhoneAbout_ezklex.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /*  */

  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    background-image: url(https://res.cloudinary.com/dcaaew01j/image/upload/v1591796189/Alessandra_Portfolio_Content/AboutMe/DesktopAbout_wxpknh.png);
    height: 1000px;
  }
`;

export default function About() {
  const constrainRef = React.useRef(null);

  return (
    <Container>
      <CardFence ref={constrainRef}>
        <AnimatedArrow />
        <AlessandraName />
        <AboutCard
          title={cardData.connecting.title}
          info={cardData.connecting.info}
          drag
          dragConstraints={constrainRef}
          top={"68%"}
          left={"46%"}
        />
        <AboutCard
          title={cardData.experience.title}
          info={cardData.experience.info}
          drag
          dragConstraints={constrainRef}
          top={"52%"}
          left={"26%"}
        />
        <AboutCard
          title={cardData.education.title}
          info={cardData.education.info}
          drag
          dragConstraints={constrainRef}
          top={"42%"}
          left={"70%"}
        />
        <AboutCard
          title={cardData.background.title}
          info={cardData.background.info}
          drag
          dragConstraints={constrainRef}
          top={"34%"}
          left={"65%"}
        />
        <AboutCard
          title={cardData.focuses.title}
          info={cardData.focuses.info}
          drag
          dragConstraints={constrainRef}
          top={"24%"}
          left={"18%"}
        />
        <AboutCard
          title={cardData.intro.title}
          info={cardData.intro.info}
          drag
          dragConstraints={constrainRef}
          top={"18%"}
          left={"10%"}
        />
      
      </CardFence>
    </Container>
  );
}

const Container = styled.div`
  /* background: yellow; */
  padding-top: 60px;
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
`;

// styled={{ position: "relative" }}

const CardFence = styled.div`
  /* background-color: orange; */
  height: 92.5vh;
`;

function AboutCard({ title, info, dragConstraints, style, top, left }) {
  const [isVisible, setVisible] = React.useState(true);
  function close() {
    setVisible(!isVisible);
  }
  return (
    <motion.div>
      {isVisible ? (
        <CardContainer
          drag
          dragConstraints={dragConstraints}
          style={style}
          left={left}
          top={top}
        >
          <CardHeader>
            <h5>{title}</h5>
            <ExitIcon onTap={close} />
          </CardHeader>
          <CardBody>
            <p>{info}</p>
          </CardBody>
        </CardContainer>
      ) : (
        ""
      )}
    </motion.div>
  );
}

const CardContainer = styled(motion.div)`
  background: #fffdfa;
  width: 300px;
  min-height: 113px;
  font-family: sans-serif;
  border: 1px solid black;

  position: absolute;
  top: ${(props) => props.top || 50};
  left: ${(props) => props.left || 50};
  bottom: ${(props) => props.bottom || 50};
  right: ${(props) => props.right || 50};

  @media ${device.tablet} {
  }
  @media ${device.laptop} {
  }
`;

const CardHeader = styled.div`
  background: #fffdfa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  margin-top: 0;
  border: 1px solid #c2c2c2;
  border-top: none;
  border-left: none;
  border-right: none;
  /*  */
  h5 {
    font-family: "GoshaSans-Bold", sans-serif;
    color: #000000;
    font-size: 18px;
    letter-spacing: 0px;
    /* line-height: 1.2; */
  }
`;

const CardBody = styled.div`
  font-family: "StagSans-Light", sans-serif;
  color: #000000;
  font-size: 17px;
  letter-spacing: 0.1px;
  line-height: 1.3;
  padding: 12px;
  padding-top: 4px;
`;

function ExitIcon({ onTap }) {
  return (
    <svg
      onClick={onTap}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
    >
      <path
        d="M 0 0 L 18 0 L 18 1 L 0 1 Z"
        transform="translate(11 20) rotate(45 9 0.5)"
        fill="hsl(0, 0%, 0%)"
      ></path>
      <path
        d="M 0 0 L 18 0 L 18 1 L 0 1 Z"
        transform="translate(11 20) rotate(-45 9 0.5)"
        fill="hsl(0, 0%, 0%)"
      ></path>
    </svg>
  );
}
