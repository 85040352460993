import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { device } from "../Utils";
import { DownArrow } from "../Icons/Arrows";
// import Footer from "../Footer";

import SuggestedProjects from "../components/SuggestedProjects";
import PROJECT_QUERY from "../queries/project/project";
import Query from "../components/Query";

export default function ProjectDetailPage({ match, location }) {
  const id = match.params.id;

  // console.log(id);
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.51 }}
    >
      <Query query={PROJECT_QUERY} id={id}>
        {({ data: { project } }) => {
          const coverImage = project.coverImage.url;
          const title = project.Title;
          const projectType = project.ProjectType;
          const projectBrief = project.ProjectBrief;
          const processText = project.processText;
          const myRoles = project.myRoles;
          const restofImages = project.outcomeRestofImages;
          return (
            <div>
              <HeaderSection initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Image style={{ backgroundImage: `url(${coverImage})` }} />
              </HeaderSection>

                <PageSections>
                  <ProjectInfo>
                    <div>
                    <h1>{title}</h1>
                
                      <h5>{projectType}</h5>
                    </div>
                    <DownArrow />
                  </ProjectInfo>
              <ProjectBriefSection>
                <SectionInfo number="—01" name="project brief" />
                {projectBrief}
              </ProjectBriefSection>


            <ProjectDetails>
              <ProcessContent1>
                  <SectionInfo number="—02" name="my roles" />
                  <p>{myRoles}</p>
                </ProcessContent1>
              

              <ProcessContent2>
                  <SectionInfo number="—03" name="the process" />
                  <p>{processText}</p>
                </ProcessContent2>
              </ProjectDetails>


              <br />
              <ImageStack>
                {restofImages.map((image) => {
                  return (
                    <img
                    src={image.url}
                    alt={image.id}
                    key={image.id}
                    style={{
                      width: "100%",
                    }}
                    />
                    );
                  })}
              </ImageStack>
              <br />
                  </PageSections>
            </div>
          );
        }}
      </Query>
      {/* END OF QUERY */}
      
      <br />
      
      <SuggestedProjects currentId={id} />
      {/* <Footer /> */}
    </Container>
  );
}

/* // IM WORKING HERE RIGHT NOW. END */

const ImageStack = styled.div`
  display: grid;
  grid-gap: 40px;
  padding: 8px;
  @media ${device.tablet} {
    padding: 0;
  }
  @media ${device.laptop} {
    padding: 0;
  }
`;

const PageSections = styled(motion.section)`
padding: 22px;
/* background: yellow; */
@media ${device.tablet}{
  /* background: red; */
  padding: 40px;
}
`;

const HeaderSection = styled(motion.section)``;

const ProjectInfo = styled.div`
  /*  */
  /* padding: 16px; */
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #222;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  /* background: pink; */
  /*  */
  display: flex;
  justify-content: space-between;
  align-items: center;

@media ${device.laptop}{
  padding-bottom: 12px;
}

  h1 {
    /* color: orange; */
    font-size: 32px;
    margin-bottom: 12px;
    @media ${device.laptop} {
      font-size: 58px;
    }
  }
  h5 {
    font-family: "StagSans-ThinItalic", sans-serif;
    font-size: 24px;
    @media ${device.laptop} {
      font-size: 36px;
    }
  }
`;

//

function SectionInfo(props) {
  return (
    <div style={{ height: 50, width: 160,  }}>
      <SectionNumber>{props.number}</SectionNumber>
      <SectionName>{props.name}</SectionName>
    </div>
  );
}

//

const Container = styled(motion.div)`
  /* padding-top: 80px; */
  width: 100%;
  height: 40vh;

  @media ${device.tablet} {
    height: 180vh;
  }
  @media ${device.laptop} {
    height: 180vh;
  }
`;

const Image = styled("div")`
  width: 100%;
  height: 610px;
  /* background: orange; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  image-rendering: auto;
  @media ${device.laptop} {
    height: 810px;
  }
`;

const ProjectBriefSection = styled.section`
  padding: 40px 0 40px 0;
  font-family: "StagSans-Thin", sans-serif;
  color: #000000;
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 1.2;
  /*  */
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    font-size: 52px;
  }
`;
//
//
//



//
const ProcessContent1 = styled.div`
  padding: 0 40px 40px 0;
  padding-top: 0;
  padding-bottom: 32px;
  white-space: pre-line;
`;

const ProcessContent2 = styled.div`
  padding-top: 0;
  padding-bottom: 32px;
`;



const SectionNumber = styled.p`
  font-family: "StagSans-Light", sans-serif;
  color: #000000;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-align: left;
`;

const SectionName = styled.p`
  overflow: hidden;
  font-family: "GoshaSans-Bold", sans-serif;
  color: #000000;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

const ProjectDetails = styled.div`
display: flex;
flex-direction: column;
width: 90%;
@media ${device.tablet} {
  flex-direction: row;
  width: 64%;
  }
`