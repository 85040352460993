import React from "react";

export function ArrowIcon({ style }) {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <path d="M 4 0 L 15 0 L 15 2 L 4 2 Z" fill="hsl(0, 0%, 0%)"></path>
      <path
        d="M 0 0 L 11 0 L 11 2 L 0 2 Z"
        transform="translate(9.5 4.5) rotate(90 5.5 1)"
        fill="hsl(0, 0%, 0%)"
      ></path>
      <path
        d="M 0.782 13.51 L 14.218 0.075 L 15.632 1.49 L 2.197 14.925 Z"
        fill="hsl(0, 0%, 0%)"
      ></path>
    </svg>
  );
}

export function BehanceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
      <path
        d="M -0 15.052 C -0.003 6.823 6.836 -0.032 15.017 -0 C 23.193 0.032 30.035 6.903 30 15.047 C 29.964 23.277 23.166 30.004 14.887 30.001 C 6.785 29.997 0.003 23.187 -0 15.052 Z M 14.966 28.798 C 22.633 28.871 28.993 22.274 28.797 14.687 C 28.611 7.408 22.355 1.09 14.799 1.199 C 7.406 1.306 1.1 7.646 1.153 15.063 C 1.207 22.591 7.492 28.839 14.966 28.798 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 5.493 21.172 L 5.493 9.109 L 11.422 9.109 C 12.817 9.109 13.949 9.911 14.369 11.197 C 14.783 12.461 14.321 13.803 13.196 14.617 C 13.171 14.635 13.149 14.656 13.136 14.666 C 13.425 14.846 13.735 14.999 13.998 15.211 C 15.18 16.161 15.434 17.678 14.823 18.995 C 14.157 20.432 13.003 21.165 11.41 21.169 C 9.536 21.174 7.661 21.17 5.787 21.17 C 5.697 21.172 5.604 21.172 5.493 21.172 Z M 7.889 18.745 C 7.939 18.753 7.97 18.762 7.999 18.762 C 9.183 18.762 10.368 18.766 11.551 18.757 C 11.679 18.756 11.815 18.712 11.931 18.652 C 12.864 18.179 12.97 16.687 12.114 16.089 C 11.769 15.849 11.379 15.744 10.962 15.744 C 10.005 15.741 9.049 15.743 8.092 15.743 C 8.025 15.743 7.959 15.754 7.888 15.76 C 7.889 16.763 7.889 17.741 7.889 18.745 Z M 7.895 13.943 C 8.951 13.943 9.984 13.976 11.014 13.931 C 11.672 13.901 12.138 13.315 12.11 12.691 C 12.082 12.063 11.582 11.533 10.951 11.522 C 9.95 11.504 8.947 11.521 7.944 11.523 C 7.931 11.523 7.918 11.539 7.895 11.552 Z M 24.781 16.96 L 18.172 16.96 C 18.072 17.669 18.665 18.739 19.364 19.129 C 20.041 19.507 20.908 19.433 21.499 18.933 C 21.865 18.623 22.069 18.21 22.186 17.753 C 22.225 17.601 22.283 17.539 22.452 17.541 C 23.119 17.552 23.786 17.546 24.451 17.547 C 24.52 17.547 24.586 17.555 24.656 17.559 C 24.342 19.452 23.13 20.851 21.141 21.113 C 19.995 21.265 18.888 21.162 17.872 20.557 C 16.668 19.842 15.99 18.764 15.791 17.394 C 15.599 16.078 15.845 14.849 16.68 13.785 C 17.44 12.814 18.458 12.302 19.674 12.168 C 20.887 12.035 22.016 12.265 23.008 13.001 C 24.049 13.772 24.595 14.833 24.731 16.111 C 24.759 16.382 24.764 16.655 24.781 16.96 Z M 22.212 15.73 C 22.166 14.66 21.181 13.879 19.992 13.96 C 19.114 14.018 18.213 14.952 18.295 15.73 Z M 22.929 11.507 L 17.549 11.507 L 17.549 9.721 L 22.929 9.721 Z"
        fill="rgb(0,0,0)"
      ></path>
    </svg>
  );
}

export function InstagramIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
      <path
        d="M 0 15.052 C -0.003 6.823 6.836 -0.032 15.017 -0 C 23.193 0.032 30.035 6.903 30 15.047 C 29.964 23.277 23.166 30.004 14.887 30.001 C 6.785 29.997 0.003 23.187 0 15.052 Z M 14.966 28.798 C 22.633 28.871 28.993 22.274 28.797 14.687 C 28.611 7.408 22.355 1.09 14.799 1.199 C 7.406 1.306 1.1 7.646 1.153 15.063 C 1.207 22.591 7.492 28.839 14.966 28.798 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 7 14.986 C 7 13.913 6.996 12.841 7 11.772 C 7.007 9.393 8.633 7.462 10.981 7.057 C 11.236 7.014 11.499 7.004 11.758 7.004 C 13.918 7 16.08 7 18.239 7 C 20.605 7 22.558 8.658 22.941 10.995 C 22.984 11.257 22.995 11.524 22.995 11.79 C 22.998 13.927 22.998 16.065 22.998 18.202 C 22.995 20.603 21.389 22.52 19.028 22.939 C 18.779 22.982 18.52 22.996 18.268 22.996 C 16.091 22.999 13.91 23.003 11.734 22.996 C 9.386 22.992 7.415 21.309 7.053 18.998 C 7.014 18.742 7.003 18.479 7.003 18.22 C 6.996 17.141 7 16.065 7 14.986 Z M 22.277 15.007 C 22.277 13.942 22.281 12.876 22.274 11.811 C 22.274 11.588 22.263 11.36 22.228 11.14 C 21.894 9.109 20.264 7.728 18.204 7.724 C 16.066 7.721 13.928 7.721 11.794 7.728 C 11.499 7.728 11.201 7.753 10.913 7.817 C 9.013 8.225 7.728 9.812 7.724 11.758 C 7.717 13.92 7.721 16.079 7.724 18.242 C 7.724 18.447 7.735 18.657 7.77 18.859 C 8.101 20.89 9.734 22.275 11.794 22.275 C 13.932 22.279 16.07 22.279 18.204 22.272 C 18.499 22.272 18.797 22.247 19.085 22.186 C 20.974 21.782 22.263 20.191 22.274 18.256 C 22.281 17.173 22.277 16.09 22.277 15.007 Z"
        fill="rgb(0,0,0)"
        strokeWidth="0.4"
        stroke="rgba(0,0,0,1)"
        strokeMiterlimit="10"
      ></path>
      <path
        d="M 15.004 19.001 C 12.81 19.005 11.006 17.208 10.998 15.007 C 10.991 12.805 12.806 10.991 15.008 10.995 C 17.21 11.002 19.007 12.802 19.003 15 C 18.999 17.198 17.202 18.998 15.004 19.001 Z M 18.271 14.993 C 18.268 13.178 16.812 11.726 14.994 11.73 C 13.179 11.733 11.726 13.192 11.73 15.007 C 11.734 16.821 13.193 18.273 15.008 18.27 C 16.822 18.266 18.271 16.807 18.271 14.993 Z M 20.086 10.636 C 20.086 11.037 19.766 11.357 19.369 11.36 C 18.967 11.364 18.634 11.034 18.634 10.632 C 18.634 10.228 18.964 9.908 19.372 9.912 C 19.777 9.915 20.086 10.231 20.086 10.636 Z"
        fill="rgb(0,0,0)"
        strokeWidth="0.4"
        stroke="rgba(0,0,0,1)"
        strokeMiterlimit="10"
      ></path>
    </svg>
  );
}

export function LinkedInIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30">
      <path
        d="M 12.902 12.018 L 16.138 12.018 L 16.138 13.309 C 16.365 13.069 16.525 12.863 16.721 12.699 C 18.797 10.956 22.077 12.114 22.625 14.787 C 22.709 15.199 22.751 15.628 22.755 16.05 C 22.771 17.676 22.761 19.302 22.761 20.928 L 22.761 21.24 L 19.45 21.24 L 19.45 20.894 C 19.45 19.405 19.464 17.917 19.441 16.429 C 19.434 16.046 19.359 15.643 19.223 15.284 C 18.96 14.593 18.339 14.297 17.536 14.403 C 16.88 14.489 16.277 15.066 16.184 15.707 C 16.161 15.874 16.155 16.046 16.155 16.215 C 16.154 17.778 16.154 19.34 16.155 20.903 L 16.155 21.243 L 12.903 21.243 C 12.902 18.183 12.902 15.117 12.902 12.018 Z M 11.078 12.031 L 11.078 21.249 L 8.189 21.249 L 8.189 12.031 Z M 9.569 10.862 C 8.319 10.834 7.571 9.931 7.85 8.841 C 7.987 8.309 8.334 7.95 8.851 7.778 C 9.555 7.544 10.244 7.577 10.822 8.086 C 11.319 8.523 11.464 9.104 11.269 9.736 C 11.068 10.391 10.576 10.724 9.92 10.837 C 9.787 10.86 9.647 10.856 9.569 10.862 Z"
        fill="rgb(0,0,0)"
      ></path>
      <path
        d="M 0 15.052 C -0.003 6.823 6.836 -0.032 15.017 -0 C 23.193 0.032 30.035 6.903 30 15.047 C 29.964 23.277 23.166 30.004 14.887 30.001 C 6.785 29.997 0.003 23.187 0 15.052 Z M 14.966 28.798 C 22.633 28.871 28.993 22.274 28.797 14.687 C 28.611 7.408 22.355 1.09 14.799 1.199 C 7.406 1.306 1.1 7.646 1.153 15.063 C 1.207 22.591 7.492 28.839 14.966 28.798 Z"
        fill="rgb(0,0,0)"
      ></path>
    </svg>
  );
}

export function DownArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60">
      <path
        d="M 31.569 0 L 31.569 53.556 L 44.321 40.726 L 46.54 42.958 L 30 59.602 L 13.46 42.958 L 15.678 40.726 L 28.431 53.556 L 28.431 0 Z"
        fill="rgb(0,0,0)"
      ></path>
    </svg>
  );
}

export function NavArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44">
      <path
        d="M 40.622 1.979 L 10.605 1.979 L 10.605 0 L 44 0 L 44 33.395 L 42.021 33.395 L 42.021 3.378 L 2.399 43 L 1 41.6 L 40.622 1.98 Z"
        fill="rgb(0,0,0)"
      ></path>
    </svg>
  );
}


export function SuggestedArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <path d="M 12.21 0.966 L 3.238 0.966 L 3.238 0 L 13.859 0 L 13.859 10.621 L 12.893 10.621 L 12.893 1.648 L 0.683 13.859 L 0 13.176 Z" fill="rgb(0,0,0)"></path>
    </svg>
  )
}