import React from "react";
import { motion } from "framer-motion";

// Animated Eye

function AnimatedEye() {
  return (
    <div
      style={{
        width: 105,
        height: 105,
        // position: "absolute",
        // right: 30,
        // top: 86,
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        gridTemplateAreas: `"area1"`,
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <motion.div
        animate={{ rotate: 360 * 2 }}
        transition={{ duration: 20, loop: Infinity }}
        style={animatedTextFrame}
      />
      <div style={round} />
      <div style={EyeFrame} />
    </div>
  );
}

const round = {
  boxSizing: "border-box",
  width: 60,
  height: 60,
  // overflow: "hidden",
  backgroundColor: "#F4E8D7",
  borderRadius: 60,
  border: "0.5px solid #222",
  gridArea: "area1",
};

const animatedTextFrame = {
  width: 105,
  height: 105,
  overflow: "visible",
  backgroundImage:
    "url(https://res.cloudinary.com/dcaaew01j/image/upload/v1598388048/Alessandra_Portfolio_Content/Icons/AnimatedEye/AnimatedEye_2-04_mjt7pw.png)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  imageRendering: "auto",
  gridArea: "area1",
};

const EyeFrame = {
  width: 105,
  height: 105,
  overflow: "visible",
  backgroundImage:
    "url(https://res.cloudinary.com/dcaaew01j/image/upload/v1598388725/Alessandra_Portfolio_Content/Icons/AnimatedEye/AnimatedEye_SansColor-06_r3n5ux.png)",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  imageRendering: "auto",
  gridArea: "area1",
};

export default AnimatedEye;
