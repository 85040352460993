import React from "react";

export default function Page404({ location }) {
  return (
    <div>
      <h1>
        Oh no! The page on{" "}
        <code style={{ background: "lightcoral" }}>{location.pathname}</code>{" "}
        doesn't exists
      </h1>
    </div>
  );
}
