import gql from "graphql-tag";

const PROJECT_QUERY = gql`
  query Projects($id: ID!) {
    project(id: $id) {
      id
      coverImage {
        url
      }
      Title
      ProjectType
      ProjectBrief
      myRoles
      processText
      processImage {
        url
      }
      outcomeText
      outcomeImage1 {
        url
      }
      outcomeImage2 {
        url
      }
      outcomeRestofImages {
        id
        formats
        url
      }
    }
  }
`;

export default PROJECT_QUERY;
